@import '../../../assets/css/base.scss';

.detail-inner {
  background: #f6f9fc;
  padding: 0 rem(5);

  .new-box {
    width: 100%;
    height: 100%;
    margin: rem(10) auto 0;

    .clear {
      display: flex;
      justify-content: space-between;

      &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }

      .box-left {
        width: 65%;
        margin-right: rem(20);

        .main-l-l-d {
          border: 2px solid #B2D234;
          margin-bottom: rem(20);

          .top-main {
            display: flex;
            flex-direction: row;
            //justify-content: space-between;
            padding: rem(10);

            .box-detail-img {
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              width: rem(400);
              height: rem(160);
              border: 1px solid #DDD;
              text-align: center;
              margin-right: rem(20);

              p {
                margin: 0;
                color: #f44;
                font-weight: bold;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }

            .normal-m {
              p {
                //margin-bottom: rem(5);
                border-bottom: rem(2) dashed #D9D9D9;
                font-size: rem(12);
                margin-bottom: rem(25);
                padding-bottom: rem(3);
                color: #888;

              }

              span:first-child {
                color: #f44;
                font-weight: bold;
              }

              .link {
                text-decoration: none;
                color: #017BC6;
              }

              .receive-coupon {
                  span {
                    color: #fff;
                  }
              }
            }
          }

          .middle-main {
            padding: rem(10);

            h4 {
              font-weight: bold;
              padding-bottom: rem(5);
              border-bottom: 1px solid #e5e5e5;
            }

            .content {
              font-size: rem(14);
              margin-bottom: rem(30);
            }

            .rich-text {
              padding: rem(20);

              img {
                width: 100%;
              }
            }

            .introduction {
              p {
                margin-bottom: rem(5);
                font-size: rem(14);
              }
            }

            .tips {
              margin-top: rem(30);
              padding: rem(20);
              border: 1px solid #CEE086;
              background: #FBFFEB;
              font-size: rem(12);

              p {
                margin-bottom: rem(5);
              }
            }
          }
        }

        .coupon-list {
          padding: rem(20);

          .coupon-list-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
          }

          h4 {
            font-weight: bold;
            padding-bottom: rem(5);
            border-bottom: 2px solid #B2D234;
          }
        }
      }

      .box-right {
        flex: 1;
        padding: rem(20);
        background: #fff;
      }
    }
  }

  .tab {
    background: #ffe995;

    .ant-tabs-nav {
      margin-bottom: 0;

      &:before {
        border-bottom: none;
      }
    }

    .ant-tabs-extra-content {
      font-size: rem(14);
    }

    .list-tab {
      margin-top: rem(10);
    }
  }

  .detail-li {
    background: #ffe995;

    li {
      background: #fff;
    }
  }

  .text-red {
    color: #ff0052;
  }
}
