/*
  px转rem
*/
@function rem($n) {
  @return $n / 10 * 1rem;
}

@-webkit-keyframes fadenum {

  /*设置内容由显示变为隐藏*/
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadenum {

  /*设置内容由显示变为隐藏*/
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadenum {

  /*设置内容由显示变为隐藏*/
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadenum {

  /*设置内容由显示变为隐藏*/
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/*根字体*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#root {
  height: auto;
}

.App {
  height: 100%;
}

.over-scroll {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.over-hidden {
  overflow: hidden;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #fff;
  font-family: 'Microsoft Yahei', Helvetica, Arial, sans-serif;
  color: #1c2438;
}

input,
button {
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border: none;
}

input {
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

input,
select,
textarea,
button {
  height: auto;
  font-family: 'Microsoft Yahei', Helvetica, Arial, sans-serif;
}

input:focus,
select:focus,
textarea:focus {
  border-color: #999;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

section {
  display: block;
}

ol,
ul {
  list-style: none;
}

img {
  border: 0;
}

a {
  text-decoration: none;
  color: #4b4b57;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus,
a:active {
  outline: none;
  text-decoration: none;
  color: #4b4b57;
  border: none;
}

/*清除*/
.clearfix:before,
.clearfix:after {
  display: block;
  clear: both;
  content: '';
  height: 0;
}

/**限制单行文本*/
.one-line {
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**位置*/
.left {
  float: left;
}

.right {
  float: right;
}

.abs {
  position: absolute;
}

.rel {
  vertical-align: middle;
  position: relative;
}

.fix {
  position: fixed;
}

.lt0 {
  left: 0;
  top: 0;
}

.lb0 {
  left: 0;
  bottom: 0;
}

.rt0 {
  right: 0;
  top: 0;
}

.rb0 {
  right: 0;
  bottom: 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/*宽度*/
.w3 {
  width: 33%;
}

.w4 {
  width: 40%;
}

.w5 {
  width: 50%;
}

.w55 {
  width: 55%;
}

.w6 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.w7 {
  width: 70%;
}

.w7 {
  width: 70%;
}

.w8 {
  width: 80%;
}

.w9 {
  width: 90%;
}

iframe {
  display: none;
}

li {
  list-style: none;
}

.error-color {
  color: #ff0000;
}

.danger-color {
  color: #cc0000;
  font-weight: bolder;
}

.mg-l5 {
  margin-left: rem(5);
}

.mg-l10 {
  margin-left: rem(10);
}

.mg-t20 {
  margin-top: rem(20);
}

.mg-l40 {
  margin-left: rem(40);
}

.all-container {
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  background: #f6f9fc;
  padding: 0;
}

