@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fade-in-left {
  opacity: 0;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  animation-duration: 1.2s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fade-in-right {
  opacity: 0;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
  animation-duration: 1.2s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fade-in-up {
  opacity: 0;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  animation-duration: 1.2s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fade-in-down {
  opacity: 0;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  animation-duration: 1.2s;
  animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

@keyframes STAR-MOVE {
  from {
    background-position: 0% 0%
  }

  to {
    background-position: 20000px 0%
  }
}

.up {
  animation: STAR-MOVE 300s linear 1s infinite;
}

.down {
  animation: STAR-MOVE 1s linear infinite;
}

@keyframes move {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.width-move {
  animation: move 5s infinite;
  -webkit-animation: move 5s infinite;
  /*Safari and Chrome*/
}

.caption {
  position: absolute;
  background-color: rgba(251, 65, 65, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: all .3s ease;
  text-align: center;
  margin: auto;
}

.mask {
  position: absolute;
  background: #1E1D1D;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: .5;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(1.15);
  }
}

.fade-in-dialog {
  pointer-events: all;
  animation: fadeIn 0.3s ease-in-out both 0.3s;
}

.fade-out-dialog {
  animation: fadeOut 0.3s ease-in-out both;
}

.top-line {
  position: absolute;
  z-index: 1;
  height: 2px;
  width: 0px;
  top: 0px;
  width: 0px;
  left: 0px;
}

.bottom-line {
  position: absolute;
  z-index: 1;
  height: 2px;
  width: 0%;
  bottom: 0px;
  right: 0px;
}

.left-line {
  height: 0;
  position: absolute;
  left: 0px;
  width: 2px;
  top: 0;
}

.right-line {
  height: 0;
  position: absolute;
  right: 0px;
  width: 2px;
  bottom: 0px;
}

@keyframes move1 {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes move2 {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  /*初始状态 透明度为0*/
  40% {
    opacity: 0;
  }

  /*过渡状态 透明度为0*/
  100% {
    opacity: 1;
  }

  /*结束状态 透明度为1*/
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
